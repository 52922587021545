import {VFC, useEffect, useCallback, useRef, FormEventHandler} from 'react';
import {useApolloClient} from '@apollo/client';
import {css} from '@emotion/react';
import {
  BroadcastFn,
  ContentModule,
  extractModuleIdentifiers,
  ModuleProperties,
  useParsedText,
  useShowId,
  useShowInstructions,
} from '@backstage-components/base';
import {ButtonComponent} from '@backstage-components/button';
import {TextInputComponent} from '@backstage-components/text-input';
import {useMachine} from '@xstate/react';
import {useSubscription} from 'observable-hooks';
import {
  useForm,
  SubmitHandler,
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';
import {
  defaultFieldData,
  SchemaType,
  AccessCodeInstructionSchema,
} from './AccessCodeDefinition';
import * as Styled from './AccessCode.styled';
import {
  AccessCodeMachine,
  type AccessCodeSuccessEvent,
} from './AccessCodeMachine';
import {AccessCodeViewMachine} from './AccessCodeViewMachine';

/**
 * `IAccessCodeProps` accounts for the property schemas of the `Button` module
 * and the `TextInput` module (more than the schema can) and also adds the
 * properties which are specified by `dependencies` access code module schema.
 */
export interface IAccessCodeProps extends ModuleProperties, SchemaType {
  magicLinkKey?: string;
}

export type AccessCodeComponentDefinition = ContentModule<
  'AccessCode',
  IAccessCodeProps & SchemaType
>;

export const AccessCodeComponent: VFC<AccessCodeComponentDefinition> = (
  definition
) => {
  const {config} = definition;
  const {
    showTermsCheckbox = defaultFieldData.showTermsCheckbox,
    accessCodeErrorMessage = defaultFieldData.accessCodeErrorMessage,
    magicLinkSupport,
    magicLinkKey = 'ac',
  } = definition.props;

  const client = useApolloClient();
  const showId = useShowId();
  const {observable, broadcast} = useShowInstructions(
    AccessCodeInstructionSchema,
    definition
  );
  const [state, dispatch] = useMachine(AccessCodeMachine, {
    context: {broadcast, showId},
  });
  const [viewState, viewDispatch] = useMachine(AccessCodeViewMachine, {
    context: {broadcast, client},
  });
  const form = useForm({
    /**
     * @param mode defines when validation is triggered.
     * AccessCode is set at 'onSubmit'
     */
    mode: 'onSubmit',
  });
  const {handleSubmit, setError, setValue, getValues} = form;

  useEffect(() => {
    if (state.matches('failure')) {
      setError('accessCode', {
        message: state.context.reason || accessCodeErrorMessage,
      });
    }
  }, [accessCodeErrorMessage, setError, state]);
  useSubscription(observable, {
    next: (instruction) => dispatch(instruction),
  });
  useSubscription(observable, {
    next: (instruction) => viewDispatch(instruction),
  });

  useEffect(() => {
    const onAccessCodeSuccess = (e: AccessCodeSuccessEvent): void => {
      const {detail} = e;
      broadcast({
        type: 'AccessCode:on-success',
        meta: {
          showId: detail.showId,
          attendeeId: detail.attendee.id,
          attendeeEmail: detail.attendee.email,
          attendeeName: detail.attendee.name,
          attendeeTags: detail.attendee.tags.join(','),
        },
      });
    };
    document.body.addEventListener('AccessCode:success', onAccessCodeSuccess);
    return () => {
      document.body.removeEventListener(
        'AccessCode:success',
        onAccessCodeSuccess
      );
    };
  }, [broadcast]);

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      // Send the instruction to the state machine, which will trigger the
      // broadcast
      if (typeof data.accessCode !== 'string') {
        return;
      }
      broadcast({
        type: 'AccessCode:verify',
        meta: {accessCode: data.accessCode.toUpperCase(), showId},
      });
    },
    [broadcast, showId]
  );

  const hasAutoSubmitExecuted = useRef(false);

  useEffect(() => {
    if (
      !window?.location ||
      config.scope !== 'attendee' ||
      magicLinkSupport === 'none' ||
      hasAutoSubmitExecuted.current === true
    ) {
      return;
    }

    // ensure effect only runs once
    hasAutoSubmitExecuted.current = true;

    if (
      magicLinkSupport === 'auto-fill' ||
      magicLinkSupport === 'auto-submit'
    ) {
      const inputKey = defaultFieldData.codeTextInputProps?.name;
      const query = window.location.search;
      const params = new URLSearchParams(query);
      const ac = params.get(magicLinkKey) ?? '';

      if (getValues(inputKey) === '') {
        setValue(inputKey, ac);
      }
      if (
        ac.length > 0 &&
        !showTermsCheckbox &&
        magicLinkSupport === 'auto-submit'
      ) {
        handleSubmit(onSubmit)();
      }
    }
  }, [
    config.scope,
    getValues,
    handleSubmit,
    magicLinkKey,
    magicLinkSupport,
    onSubmit,
    setValue,
    showTermsCheckbox,
  ]);

  if (
    viewState.matches('login') ||
    viewState.matches('loginPending') ||
    viewState.matches('resendSuccess')
  ) {
    return (
      <VerifyAccessCode
        {...definition}
        broadcast={broadcast}
        error={viewState.context.error ?? undefined}
        form={form}
        isLoading={viewState.value === 'loginPending'}
        isResendSuccess={viewState.value === 'resendSuccess'}
        showId={showId}
      />
    );
  } else if (
    viewState.matches('resend') ||
    viewState.matches('resendPending')
  ) {
    const resendEmailNotFoundErrorMessage =
      viewState.context.code === 'AttendeeNotFound'
        ? definition.props.resendAccessCodeFormContent?.emailErrorMessage
        : undefined;
    return (
      <ResendAccessCode
        {...definition}
        broadcast={broadcast}
        error={
          resendEmailNotFoundErrorMessage ??
          viewState.context.error ??
          undefined
        }
        isLoading={viewState.value === 'resendPending'}
        showId={showId}
      />
    );
  } else {
    // Access Code verification success
    return null;
  }
};

interface VerifyAccessCodeProps extends AccessCodeComponentDefinition {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  error?: string;
  form: Pick<
    UseFormReturn<FieldValues>,
    'formState' | 'handleSubmit' | 'register'
  >;
  isLoading?: boolean;
  isResendSuccess?: boolean;
  showId: string;
}

/**
 * The view to display when the component is in the "verify" view state
 * @private exported for tests
 */
export const VerifyAccessCode: VFC<VerifyAccessCodeProps> = (props) => {
  const {
    broadcast,
    error,
    form,
    isLoading = false,
    isResendSuccess = false,
    showId,
    ...definition
  } = props;
  const moduleIdentifiers = extractModuleIdentifiers(definition);

  const {id, mid, config} = definition;
  const checkboxId = `terms-checkbox-${id}`;
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    codeTextInputProps,
    termsLinkProps,
    resendButtonProps,
    submitButtonProps,
    resendAccessCodeFormContent,
    showResendLink = defaultFieldData.showResendLink,
    showTermsCheckbox = defaultFieldData.showTermsCheckbox,
    validationMessages,
  } = definition.props;

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      // Send the instruction to the state machine, which will trigger the
      // broadcast
      if (typeof data.accessCode !== 'string') {
        return;
      }
      broadcast({
        type: 'AccessCode:verify',
        meta: {accessCode: data.accessCode.toUpperCase(), showId},
      });
    },
    [broadcast, showId]
  );

  const validationOptions = {
    required:
      validationMessages?.accessCodeRequired ??
      defaultFieldData.validationMessages.accessCodeRequired,
  };

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;
  const {register, formState, handleSubmit} = form;
  const errors = formState.errors;

  const termsLinkContent = useParsedText(
    termsLinkProps?.content ?? 'No content for terms link provided'
  );
  const successMessage =
    resendAccessCodeFormContent?.successMessage ??
    defaultFieldData.resendAccessCodeFormContent.successMessage;

  return (
    <Styled.Container
      id={id}
      css={styles}
      className="access-code-wrapper access-code-verify"
    >
      <Styled.Title className="access-code-title" titleColor={titleColor}>
        {title || defaultFieldData.title}
      </Styled.Title>
      <Styled.Subtitle
        className="access-code-subtitle"
        subtitleColor={subtitleColor}
      >
        {subtitle || defaultFieldData.subtitle}
      </Styled.Subtitle>
      <Styled.Form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {termsLinkProps && showTermsCheckbox && (
          <Styled.CheckboxInputGroup className="access-code-checkbox-input-group">
            <Styled.Checkbox
              id={checkboxId}
              role="checkbox"
              className="access-code-checkbox-input"
              type="checkbox"
              {...register('terms', {
                required:
                  validationMessages?.checkboxRequired ||
                  defaultFieldData.validationMessages.checkboxRequired,
              })}
            />
            <Styled.CheckboxLabel
              htmlFor={checkboxId}
              className="access-code-checkbox-label"
            >
              {termsLinkContent}
            </Styled.CheckboxLabel>
          </Styled.CheckboxInputGroup>
        )}
        <Styled.CodeInputGroup className="access-code-text-input-group">
          <TextInputComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-text-input`}
            id={`${id}-access-code-text-input`}
            component={'TextInput'}
            props={{
              disabled: isLoading,
              parentRegister: register,
              ...codeTextInputProps,
              name: defaultFieldData.codeTextInputProps?.name,
              inputType: defaultFieldData.codeTextInputProps?.inputType,
              validationOptions: validationOptions ?? {
                required:
                  defaultFieldData.validationMessages.accessCodeRequired,
              },
              styleAttr: `${Styled.codeTextInputStyle}
              ${codeTextInputProps?.styleAttr}`,
            }}
          />
          {!(
            defaultFieldData.submitButtonProps &&
            'href' in defaultFieldData.submitButtonProps
          ) && (
            <ButtonComponent
              config={config}
              {...moduleIdentifiers}
              mid={`${mid}-access-code-submit-button`}
              id={`${id}-button`}
              component={'Button'}
              props={{
                ...submitButtonProps,
                disabled: isLoading,
                isLoading,
                children:
                  submitButtonProps?.children ||
                  defaultFieldData.submitButtonProps?.children,
                border: defaultFieldData.submitButtonProps?.border,
                htmlType: 'submit',
              }}
            />
          )}
        </Styled.CodeInputGroup>

        <SuccessMessage
          className="access-code-text-input-success-message"
          message={isResendSuccess ? successMessage : undefined}
        />
        <ErrorMessage
          className="access-code-text-input-error-message"
          error={
            error ??
            errors?.['terms']?.message ??
            errors?.['accessCode']?.message
          }
        />
      </Styled.Form>

      {resendButtonProps && showResendLink && (
        <div className="resend-button-component">
          <ButtonComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-resend-button`}
            component={'Button'}
            props={{
              onClick: () =>
                broadcast({type: 'AccessCode:resend-view', meta: {}}),
              ...resendButtonProps,
              background: {buttonColor: 'transparent'},
              children:
                resendButtonProps.children ||
                defaultFieldData.resendButtonProps?.children,
              typography:
                resendButtonProps.typography ||
                defaultFieldData.resendButtonProps?.typography,
            }}
          />
        </div>
      )}
    </Styled.Container>
  );
};

interface ResendAccessCodeProps extends AccessCodeComponentDefinition {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  error?: string;
  isLoading?: boolean;
  showId: string;
}

/**
 * The view to display when the component is in the "resend" view state
 * @private exported for tests
 */
export const ResendAccessCode: VFC<ResendAccessCodeProps> = (props) => {
  const {broadcast, error, isLoading = false, showId, ...definition} = props;
  const {id, mid, config} = definition;
  const moduleIdentifiers = extractModuleIdentifiers(definition);
  const {cid: coreId} = moduleIdentifiers;
  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;
  const formRef = useRef<HTMLFormElement | null>(null);
  const {
    codeTextInputProps,
    resendAccessCodeFormContent,
    resendButtonProps,
    submitButtonProps,
    validationMessages,
    titleColor,
    subtitleColor,
  } = definition.props;
  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (!formRef.current) {
        return;
      }
      const data = new FormData(formRef.current);
      const email = data.get('email');
      if (typeof email !== 'string' || email === '') {
        broadcast({
          type: 'AccessCode:on-resend-failure',
          meta: {
            reason:
              validationMessages?.emailRequired ?? 'Email must be provided',
          },
        });
      } else {
        broadcast({
          type: 'AccessCode:resend',
          meta: {coreId, email, showId},
        });
      }
    },
    [broadcast, coreId, showId, validationMessages?.emailRequired]
  );
  const backLabel =
    resendAccessCodeFormContent?.backButtonText ??
    defaultFieldData.resendAccessCodeFormContent.backButtonText;
  const title =
    resendAccessCodeFormContent?.title ??
    defaultFieldData.resendAccessCodeFormContent.title;
  const subtitle =
    resendAccessCodeFormContent?.subtitle ??
    defaultFieldData.resendAccessCodeFormContent.subtitle;
  const submitLabel =
    resendAccessCodeFormContent?.submitButtonLabel ??
    defaultFieldData.resendAccessCodeFormContent.submitButtonLabel;
  const placeholder =
    resendAccessCodeFormContent?.placeholder ??
    defaultFieldData.resendAccessCodeFormContent.placeholder;

  return (
    <Styled.Container
      id={id}
      css={styles}
      className="access-code-wrapper access-code-resend"
    >
      <Styled.Title className="access-code-title" titleColor={titleColor}>
        {title}
      </Styled.Title>
      <Styled.Subtitle
        className="access-code-subtitle"
        subtitleColor={subtitleColor}
      >
        {subtitle}
      </Styled.Subtitle>
      <Styled.Form ref={formRef} autoComplete="off" onSubmit={handleSubmit}>
        <Styled.CodeInputGroup className="access-code-text-input-group">
          <TextInputComponent
            config={config}
            {...moduleIdentifiers}
            id={`${id}-access-code-text-input`}
            mid={`${mid}-access-code-text-input`}
            component="TextInput"
            props={{
              ...codeTextInputProps,
              disabled: isLoading,
              name: 'email',
              inputType: 'text',
              placeholder: placeholder,
              styleAttr: `${Styled.codeTextInputStyle}
              ${codeTextInputProps?.styleAttr}`,
            }}
          />
          {!(
            defaultFieldData.submitButtonProps &&
            'href' in defaultFieldData.submitButtonProps
          ) && (
            <ButtonComponent
              config={config}
              {...moduleIdentifiers}
              id={`${id}-access-code-submit-button`}
              mid={`${mid}-access-code-submit-button`}
              component={'Button'}
              props={{
                ...submitButtonProps,
                disabled: isLoading,
                isLoading,
                children: submitLabel,
                border: defaultFieldData.submitButtonProps?.border,
                htmlType: 'submit',
              }}
            />
          )}
        </Styled.CodeInputGroup>
        <ErrorMessage
          className="access-code-text-input-error-message"
          error={error}
        />
      </Styled.Form>
      <div className="resend-button-component">
        <ButtonComponent
          config={config}
          {...moduleIdentifiers}
          id={`${id}-access-code-resend-button`}
          mid={`${mid}-access-code-resend-button`}
          component={'Button'}
          props={{
            onClick: () => broadcast({type: 'AccessCode:login-view', meta: {}}),
            ...resendButtonProps,
            background: {buttonColor: 'transparent'},
            children: backLabel,
            typography:
              resendButtonProps?.typography ||
              defaultFieldData.resendButtonProps?.typography,
          }}
        />
      </div>
    </Styled.Container>
  );
};

/**
 * Displays a stylized error message if an `error` string is provided.
 * @private exported for tests
 */
export const ErrorMessage: VFC<{className: string; error?: string}> = (
  props
) => {
  const {className, error} = props;
  if (error) {
    return (
      <Styled.ErrorMessage data-testid={className} className={className}>
        {error}
      </Styled.ErrorMessage>
    );
  } else {
    return null;
  }
};

/**
 * Displays a stylized error message if a `message` string is provided.
 * @private exported for tests
 */
export const SuccessMessage: VFC<{className: string; message?: string}> = (
  props
) => {
  const {className, message} = props;
  if (message) {
    return (
      <Styled.SuccessMessage data-testid={className} className={className}>
        {message}
      </Styled.SuccessMessage>
    );
  } else {
    return null;
  }
};
